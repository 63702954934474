import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

import HeroText from "../components/heroText";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";


const Index = () => {
  return (
    <Layout>
      <SEO title="Ατομική Ψυχοθεραπεία" keywords={[`ψυχοθεραπεία`, `ατομική`, `ατομική ψυχοθεραπεία`]} />
      <StaticQuery
        query={graphql`
          query {
            placeholderImage: file(relativePath: { eq: "atomiki.jpg" }) {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        `}
        render={(data) => (
          <div style={{ position: "relative" }}>
            <Img
              fluid={data.placeholderImage.childImageSharp.fluid}
              // style={{ height: "400px" }}
              style={{ height: "100vh" }}
            />
            
            <HeroText
              title="Ατομική Ψυχοθεραπεία"
              styles={{ textAlign: "center" }}
            />
            <div
              style={{
                height: "100%",
                width: "100%",
                background: "rgba(0,0,0,0.3)",
                position: "absolute",
                top: "0px",
              }}
            ></div>
          </div>
        )}
      />

      <div
        className="container main-content"
        style={{ marginTop: "52px", zIndex: "100", position: "relative" }}
      >
        <p>
          Η ψυχοθεραπεία, που ασκείται πάνω από εκατό χρόνια τώρα, είναι μία
          επιστημονική θεραπευτική μέθοδος η οποία συνεχώς μελετάται και
          εξελίσσεται. Ο ψυχοθεραπευτής, μπορεί να είναι ψυχολόγος, ψυχίατρος η
          άλλος επαγγελματίας ψυχικής υγείας που έχει εκπαιδευτεί επι σειρά ετών
          προκειμένου να αποκτήσει την ιδιότητα του ψυχοθεραπευτή.{" "}
        </p>
        <p>
          Με αρχική κατεύθυνση την ψυχαναλυτική/ψυχοδυναμική, σταδιακά, έχουν
          εξελιχθεί και άλλες θεραπευτικές κατευθύνσεις όπως η υπαρξιακή, η
          προσωποκεντρική, η συστημική, η γνωστική/συμπεριφορική κ.α.
        </p>
        <div className="row">
          <div className="col-md-7">
            <p>
              Πιο συγκεκριμένα, η ψυχοθεραπεία είναι μια θεραπευτική μέθοδος που
              βασίζεται ιδιαίτερα στη σχέση που αναπτύσσεται μεταξύ θεραπευτή
              και θεραπευόμενου καθώς και στη μεταξύ τους συνεργασία. Η
              συνεργασία αυτή, δεν μπορεί να επιτευχθεί χωρίς εμπιστοσύνη,
              κατανόηση και ασφάλεια ανάμεσα σε θεραπευτή και θεραπευόμενο.
              Κύριο μέλημα του θεραπευτή, δεν είναι να ασκήσει κριτική, αλλά, να
              βοηθήσει τον θεραπευμένο να κατανοήσει τον εαυτό του. Και οι δυο
              λοιπόν, πορεύονται μαζί και συμμετέχουν ενεργά προκειμένου να
              ορίσουν από κοινού θεραπευτικούς στόχους για την βελτίωση της ζωής
              του θεραπευόμενου σε προσωπικό, εργασιακό επίπεδο κ.α.{" "}
            </p>
            <p>
              Η ψυχοθεραπεία έχει φανεί πολύ ωφέλιμη για ορισμένους ανθρώπους
              που έχουν βιώσει δυσκολίες σε κάποια φάση στη ζωή τους. Οι
              δυσκολίες αυτές μπορεί να είναι μια απώλεια, για παράδειγμα ένας
              χωρισμός ή ένας θάνατος, ή μια τραυματική εμπειρία.{" "}
            </p>
            <p>
              Ακόμη, άλλα αιτήματα και δυσκολίες, αφορούν σε ψυχοσωματικά
              συμπτώματα, όπως για παράδειγμα εμφάνιση δερματικών συμπτωμάτων ή
              πόνοι στο σώμα, σε έντονα αισθήματα άγχους, θλίψης, μοναξιάς,
              πανικού, έλλειψης ευχαρίστησης και ανησυχίας, αίσθημα κενού και
              αδιέξοδου στη ζωή, σε διάφορες φοβίες.
            </p>
          </div>
          <div className="col-md-4 offset-1">
            <section className="testimonial">
              <div className="inner">
                <div className="cederholm" id="quote-wrapper">
                  <div className="quote">
                    <blockquote id="quote-quote">
                      Η αγάπη είναι η μόνη υγιής και ικανοποιητική απάντηση στο
                      πρόβλημα της ανθρώπινης ύπαρξης.
                    </blockquote>
                    <cite id="quote-attrib" style={{fontWeight: "300", fontSize: 24}}>Erich Fromm</cite>
                  </div>
                  <div className="portrait"></div>
                </div>
              </div>
            </section>
          </div>
        </div>

        <p>
          Τέλος, άλλα ζητήματα στα οποία βασίζονται και προσαρμόζονται οι
          θεραπευτικοί στόχοι, ζητήματα για τα οποία το άτομο χρειάζεται βοήθεια
          ώστε να μπορέσει να τα ξεπεράσει προκειμένου να εξελιχθεί και να
          βελτιωθεί, μπορεί να είναι προβλήματα στην εργασία, στις διαπροσωπικές
          σχέσεις ιδιαίτερα στην οικογένεια ή στη περίπτωση που το ίδιο μοτίβο
          σχέσεων επαναλαμβάνεται στην επιλογή συντρόφου.{" "}
        </p>
        <p>
          Η ψυχοθεραπεία που λαμβάνει χώρα σε ένα συγκεκριμένο πλαίσιο, τo
          γραφείο του θεραπευτή και έχει διάρκεια 45 με 50 λεπτά, αποτελείται
          από τακτικές συνεδρίες, συνήθως μια φορά την εβδομάδα. Σε άλλες
          περιπτώσεις, κάποιες συνεδρίες πραγματοποιούνται και δυο φορές την
          εβδομάδα, ή δυο φορές το μήνα, ανάλογα βέβαια με το αίτημα και τα
          χαρακτηριστικά του θεραπευόμενου. Ο θεραπευτικός στόχος, η συχνότητα
          των συνεδριών και το κόστος τους, συμφωνείται από κοινού στην αρχή,
          αλλά, μπορεί να αναθεωρηθεί -και πάλι από κοινού-, αν κριθεί
          απαραίτητο στην πορεία.
        </p>
        <p>
          Η ψυχοθεραπεία μπορεί να είναι ατομική, ομαδική, οικογενειακή ή
          ζεύγους πάλι ανάλογα με το αίτημα και τις ανάγκες του θεραπευομενου.
        </p>
      </div>
    </Layout>
  );
};

export default Index;
